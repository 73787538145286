import React, { useEffect, useState } from "react";
import { Button, Image, Col, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import ImageGallery from "react-image-gallery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

function Header() {
  const [type, setType] = useState("");
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  const images = [
    {
      original: require("../images/gallery/gallery-haji-khusus1.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus1.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus2.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus2.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus2.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus2.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus3.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus3.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus4.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus4.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus5.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus5.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus6.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus6.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus7.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus7.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus8.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus8.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus9.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus9.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus10.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus10.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus11.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus11.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus12.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus12.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
    {
      original: require("../images/gallery/gallery-haji-khusus13.webp"),
      thumbnail: require("../images/gallery/gallery-haji-khusus13.webp"),
      originalWidth: "300px",
      originalHeight: "500px",
      thumbnailHeight: "120px",
      thumbnailWidth: "70px",
    },
  ];

  const getWhatsApp = async () => {
    try {
      const response = await axios(
        `${process.env.REACT_APP_API_URL}${"wa-rotator"}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );

      const whatsappMessage = `Saya ${
        document.getElementById("nama").value
      } dari ${
        document.getElementById("domisili").value
      }, tertarik dengan program haji khusus Ternakita. Mohon informasinya.`;
      const whatsappURL = `https://wa.me/${
        response.data?.data?.phone
      }?text=${encodeURIComponent(whatsappMessage)}`;

      return window.open(whatsappURL, "_blank", "rel=noopener noreferrer");
    } catch (error) {
      return console.error("Error fetching WhatsApp number:", error);
    }
  };

  const scrollToRef = () => {
    if (isMobileDevice()) {
      window.scrollTo(0, 3000);
    } else {
      window.scrollTo(0, 9500);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="header position-relative">
      <>
        <Image
          src={require("../images/mobile/lp-hajikhusus-mobile-1.jpg")}
          className="img-mobile"
          style={{ width: "100%" }}
        />
        <Image
          src={require("../images/mobile/lp-hajikhusus-mobile-2.jpg")}
          className="img-mobile"
          style={{ width: "100%" }}
        />
        <Col className="p-3 mb-2" style={{ backgroundColor: "#E9E9E9" }}>
          <iframe
            width="100%"
            height="817"
            src="https://www.youtube.com/embed/-N6luhOV-Iw"
            title="Program Haji Khusus tanpa antri"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </Col>
        <Col className="p-3">
          <button
            className="btn-agen w-100"
            role="button"
            onClick={() => scrollToRef()}
          >
            Saya Ingin Berhaji
          </button>
        </Col>
        <Image
          src={require("../images/mobile/lp-hajikhusus-mobile-4.jpg")}
          className="img-mobile"
          style={{ width: "100%" }}
        />
        <Image
          src={require("../images/mobile/lp-hajikhusus-mobile-5.jpg")}
          className="img-mobile"
          style={{ width: "100%" }}
        />
        <Image
          src={require("../images/mobile/lp-hajikhusus-mobile-6.jpg")}
          className="img-mobile"
          style={{ width: "100%" }}
        />
        <Image
          src={require("../images/mobile/lp-hajikhusus-mobile-7.jpg")}
          className="img-mobile"
          style={{ width: "100%" }}
        />
        <Col style={{ backgroundColor: "#F8CD29" }} className="p-3">
          <Form>
            <Form.Control placeholder="Nama" id="nama" className="mb-3" />
            <Form.Control
              placeholder="Domisili"
              id="domisili"
              className="mb-4"
            />
            <Button className="w-100 btn-agen" onClick={() => getWhatsApp()}>
              Saya Ingin Mendaftar Haji Khusus
            </Button>
          </Form>
        </Col>
        <Image
          src={require("../images/mobile/lp-hajikhusus-mobile-8.jpg")}
          className="img-mobile"
          style={{ width: "100%" }}
        />
        <Col className="p-3 text-center" style={{ backgroundColor: "#ffff" }}>
          <ImageGallery items={images} />
        </Col>
        <Image
          src={require("../images/mobile/lp-hajikhusus-mobile-9.jpg")}
          className="img-mobile"
          style={{ width: "100%" }}
        />
      </>
    </div>
  );
}

export default Header;
